<div style="width: 100%;">
  <div class="resumeAll">
    <div class="row d-print-none">

      <h4 class="col-xl-1 col-lg-2 my-1">
        Detail:
      </h4>
      <app-radio-list class="col-xl-5 col-lg-10 my-1" name="resumeDetail" [(ngModel)]="detailLevel"
        [options]="[{ id: -1, value: 'Custom' },{ id: 2, value: 'Standard' },{ id: 4, value: 'Extended' }]">
      </app-radio-list>

      <h4 class="col-xl-1 col-lg-2 my-1">
        Style:
      </h4>
      <app-radio-list class="col-xl-5 col-lg-10 my-1" name="display" [(ngModel)]="programmingDisplay"
        [options]="[{ id: true, value: 'Stylized' },{ id: 'dense', value: 'Dense'},{ id: false, value: 'Minimalist' }]">
      </app-radio-list>

    </div>

    <div class="resumePage" [ngClass]="{'calibri': !programmingDisplay}">
      <app-applicant-info [applicant]="applicant" [programmingDisplay]="programmingDisplay" >
      </app-applicant-info>
      <div class="row" style="page-break-inside: avoid;" *ngFor="let jobModel of jobsSource">
        <app-job-info [programmingDisplay]="programmingDisplay" class="col-sm-12" [jobModel]="jobModel"></app-job-info>
      </div>
    </div>
    <div class="row" *ngIf="showDetails">
      <div class="col-12 text-center">
        <span class="d-none d-print-inline font-italic">
          Live Resume available at https://resume.brian-arnold.dev/
        </span>
      </div>
    </div>
    <div class="row d-print-none">
      <div class="col-xl-3 col-lg-12">
        <div class="row">
          <div class="col-12">
            <a class="pdfBadge btn btn-success mt-2" href="resume.pdf">
              Minimalist PDF version <fa-icon [icon]="faFilePdf" size="lg"></fa-icon>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <a class="pdfBadge btn btn-success mt-2" href="resume_nocontact.pdf">
              Contactless PDF version <fa-icon [icon]="faFilePdf" size="lg"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-9 d-xl-block d-none">
        <div class="row">
          <div *ngFor="let tech of techUsed" class="col-xl-6 text-right mt-2 codeText">
            {{tech.desc}}
            <a class="techBadge btn" [ngClass]="tech.class" [href]="tech.url" >
              {{tech.name}}
              <fa-icon size="lg" [icon]="tech.icon"></fa-icon>
            </a>
          </div>
        </div>
      </div>

  </div>

</div>

<ng-template #skillChecklist>
  <app-skill-check-list *ngIf="detailLevel == -1"></app-skill-check-list>
</ng-template>
