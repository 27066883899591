<div class="card" [ngClass]="{ 'codeDisplay': programmingDisplay === true, 'denseCode': programmingDisplay === 'dense' }">
  <div class="card-header blockHeader">
    <strong>{{experienceDetail.desc}}</strong>
  </div>
  <div class="card-body blockComment">
    <ul class="list-unstyled singleColumn">
      <li class="indentFirstLine" *ngFor="let detail of experienceDetail.details">
        {{detail}}
      </li>
    </ul>
  </div>
</div>
